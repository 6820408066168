<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <b-card>
          <b-card-header>
            <b-row no-gutters class="w-100">
              <b-col md="12">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-popover.hover.focus="
                        '⚪️La fiche statistique mensuelle des agences sportives est éditable par les promoteurs d’établissements et leurs agents. Elle permet de collecter des données sur le fonctionnement mensuel de l\'agence.⚪️ La fiche est remplie une fois par mois et est visible par le super utilisateur et les agents du MTCA.'
                      "
                      size="lg"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Information"
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <b-card-img
                  :src="
                    require('@/assets/images/illustration/Aéroport de cotonou.jpg')
                  "
                  alt="Image"
                  class="rounded-0 my-2"
                />
              </b-col>
              <b-col
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h1 class="m-auto text-center">FICHE STATISTIQUE</h1>
                  <br />
                  <h4 class="m-auto text-center">
                    Services sportifs et récréatifs
                  </h4>
                </div>
              </b-col>
            </b-row>
          </b-card-header>
          <content-loader
            v-if="isCheckingForLastForm"
            view-box="0 0 400 460"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <circle cx="29" cy="30" r="17" />
            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
            <rect x="-7" y="60" rx="2" ry="2" width="408" height="132"
          /></content-loader>
          <b-card-body v-if="!isCheckingForLastForm" id="entete" class="mt-2">
            <FormHeader
              :input-data="initialsValues"
              @onFormSuccess="
                ($event) => {
                  infos = { ...infos, ...$event };
                }
              "
            />
          </b-card-body>
        </b-card>
      </div>
      <h5 class="d-flex justify-content-end">
        Tout les champs avec la mention<RequiredStarComponent /> sont requis
      </h5>

      <b-row v-if="!isCheckingForLastForm">
        <!-- navigation -->
        <b-col lg="3" class="d-none d-lg-block">
          <div>
            <b-card>
              <b-list-group>
                <!-- <b-list-group-item href="#entete">
                  EN TETE
                </b-list-group-item> -->
                <b-list-group-item href="#renseignementsgeneraux">
                  RENSEIGNEMENTS GÉNÉRAUX
                </b-list-group-item>
                <b-list-group-item href="#capaciteaccueil">
                  CAPACITÉ D'ACCEUIL
                </b-list-group-item>
                <b-list-group-item href="#emplois"> EMPLOIS </b-list-group-item>
                <b-list-group-item href="#expoiltationmensuelle">
                  RENSEIGNEMENTS EXPLOITATION DU MOIS
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col>
        <!-- body -->
        <b-col lg="9" class="pb-2">
          <!-- RENSEIGNEMENTS GENERAUX  -->
          <div>
            <b-card id="renseignementsgeneraux">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <b-row>
                  <!-- Nom de l'établissement -->
                  <b-col md="6">
                    <b-form-group label="Nom Établissement">
                      <b-form-input
                        v-model="infos.nomEtablissement"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- adresse complète -->
                  <b-col md="6">
                    <b-form-group
                      label="Type d’activité sportives et récréatives"
                    >
                      <b-form-input
                        v-model="get_sous_type"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Nom du promoteur -->
                  <b-col md="6">
                    <b-form-group label="Nom Promoteur">
                      <b-form-input
                        v-model="infos.nomPromoteur"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Prénom du promoteur -->
                  <b-col md="6">
                    <b-form-group label="Prénom(s) Promoteur">
                      <b-form-input
                        v-model="infos.prenomsPromoteur"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Nationalité du promoteur -->
                  <b-col md="12">
                    <b-form-group>
                      <template #label>
                        Nationalité du promoteur<RequiredStarComponent
                          class="mr-2"
                        />
                      </template>

                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                        v-model="infos.nationalitePromoteur"
                        type="text"
                        disabled
                      />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- adresse complète -->
                  <b-col md="6">
                    <b-form-group label="Adresse Complète Établissement ">
                      <b-form-input
                        v-model="infos.adresseComplete"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- telephone -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>Numéros de Téléphone</div>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Num 1">
                      <b-form-input
                        v-model="infos.telephone1"
                        type="text"
                        placeholder=""
                        v-phonenumber-field
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Num 2">
                      <b-form-input
                        v-model="infos.telephone2"
                        type="text"
                        placeholder=""
                        v-phonenumber-field
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="mt-1" md="4">
                    <b-form-group label-cols-md="3" label="Num 3">
                      <b-form-input
                        v-model="infos.telephone3"
                        type="text"
                        placeholder=""
                        v-phonenumber-field
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- agrément -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>
                      Détenez-vous un agrément du Ministère
                      ?<RequiredStarComponent class="mr-2" /></div
                  ></b-col>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.agrementMinistere"
                      :options="agrementOptions"
                      class="demo-inline-spacing ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-col
                    v-if="infos.agrementMinistere == 'oui'"
                    class="mt-1"
                    md="10"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Référence Agrément"
                    >
                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model="infos.referenceAgrement"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-else class="mt-1" md="6" />
                </b-row>
                <!-- type transport -->
                <b-row v-if="!currentUserSousTypeEtabissement" class="mt-2">
                  <b-col md="12"> Type de transport </b-col>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.typeEtablissement"
                      :options="typesTransport"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>

                  <b-col
                    v-if="infos.typeEtablissement == 'Autres'"
                    class=""
                    md="12"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Type Établissement"
                    >
                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model="infos.typeEtablissementAutres"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </div>

          <!-- CAPACITÉ D'ACCEUIL -->
          <div>
            <b-card id="capaciteaccueil">
              <b-card-header>
                <h3>CAPACITÉ D'ACCEUIL</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <b-row>
                  <b-col sm="12">
                    <b-form-group>
                      <label
                        for="NbreTotalChambre"
                        class="float-left col-form-label col-form-label-md"
                        >Nombre de places
                        <br />disponibles:<RequiredStarComponent class="mr-5"
                      /></label>

                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model.number="
                            infos.capacitesAccueil.nombrePlacesDisponibles
                          "
                          type="number"
                          v-digits-only="{ length: 16 }"
                          placeholder="20"
                          class="float-left w-88"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <label
                        for="NbreTotalChambre"
                        class="float-left mr-1 col-form-label col-form-label-md"
                        >Nombre visiteurs <br />nationaux:<RequiredStarComponent
                          class="mr-5"
                      /></label>

                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model.number="
                            infos.capacitesAccueil.nombreVisitesNationaux
                          "
                          type="number"
                          v-digits-only="{ length: 16 }"
                          placeholder="345"
                          class="float-left w-88"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <label
                        for="NbreTotalChambre"
                        class="float-left mr-1 col-form-label col-form-label-md"
                        >Nombre visiteurs<br />
                        internationaux:<RequiredStarComponent class="mr-2"
                      /></label>

                      <validation-provider v-slot="{ errors }" rules="required">
                        <b-form-input
                          v-model.number="
                            infos.capacitesAccueil.nombreVisitesInterNationaux
                          "
                          type="number"
                          v-digits-only="{ length: 16 }"
                          placeholder="345"
                          class="float-left w-88"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </div>

          <!-- EMPLOIS  -->
          <div>
            <b-card id="emplois">
              <b-card-header class="d-flex justify-content-between">
                <h3>EMPLOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- employés salariés -->
                <div class="table-responsive text-center">
                  <h5>Nombre d'employés salariés<RequiredStarComponent /></h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.hommeTempspleinNationaux
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .hommeTempspartielNationaux
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.femmeTempspleinNationaux
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .femmeTempspartielNationaux
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries.femmeTempspleinNationaux
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielNationaux
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.hommeTempspleinEtrangers
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries.femmeTempspleinEtrangers
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinEtrangers +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielEtrangers +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries.hommeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.femmeTempspleinNationaux +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .femmeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries.hommeTempspleinNationaux +
                                infos.employesSalaries
                                  .femmeTempspleinNationaux +
                                infos.employesSalaries
                                  .hommeTempspleinEtrangers +
                                infos.employesSalaries.femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesSalaries
                                .hommeTempspartielNationaux +
                                infos.employesSalaries
                                  .femmeTempspartielNationaux +
                                infos.employesSalaries
                                  .hommeTempspartielEtrangers +
                                infos.employesSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- nouveeaux employés du mois  -->
                <div class="table-responsive text-center mt-3">
                  <h5>
                    Nombre de nouveaux emplois créés au cours du
                    mois<RequiredStarComponent />
                  </h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Salariés recrutés au cours du mois</th>
                        <th scope="col">Hommes</th>
                        <th scope="col">Femmes</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nombre</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.hommes"
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.femmes"
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(infos.nouveauEmplois.hommes) +
                            normalizeNumericValue(infos.nouveauEmplois.femmes)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- employés non salariés -->
                <div class="table-responsive text-center mt-3">
                  <h5>
                    Nombre d'employés non salariés<RequiredStarComponent />
                  </h5>
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowspan="2">
                          Nombre d'employés non salariés
                        </th>
                        <th scope="col" colspan="2">Hommes</th>
                        <th scope="col" colspan="2">Femmes</th>
                        <th scope="col" colspan="2">Total</th>
                      </tr>
                      <tr>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                        <th scope="col">Temps plein</th>
                        <th scope="col">Temps partiel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nationaux</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspleinNationaux
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspartielNationaux
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Étrangers</th>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                              "
                              type="number"
                              v-digits-only="{ length: 16 }"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .femmeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .femmeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspleinNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspleinEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspleinEtrangers
                            )
                          }}
                        </td>
                        <td>
                          {{
                            normalizeNumericValue(
                              infos.employesNonSalaries
                                .hommeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .femmeTempspartielNationaux +
                                infos.employesNonSalaries
                                  .hommeTempspartielEtrangers +
                                infos.employesNonSalaries
                                  .femmeTempspartielEtrangers
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <!-- RENSEIGNEMENTS EXPLOITATION DU MOIS  -->
          <div>
            <b-card id="expoiltationmensuelle">
              <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS D'EXPLOITATION DU MOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- durree moyenne visite  -->
                <b-form-group>
                  <label
                    for=""
                    class="float-left mr-5 col-form-label col-form-label-md"
                    >Durée moyenne<br />
                    des visites<RequiredStarComponent class="mr-4"
                  /></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-input
                      v-model.number="infos.durreeMoyenneVisite"
                      type="number"
                      v-digits-only="{ length: 16 }"
                      min="0"
                      placeholder="Veuillez saisir la durée moyenne des visites"
                      class="float-left w-88 ml-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Chiffre d'affaire global nationaux -->
                <b-form-group>
                  <label
                    for=""
                    class="float-left col-form-label col-form-label-md"
                    >Chiffre d'affaire<br />global
                    nationaux(FCFA)<RequiredStarComponent class="mr-2"
                  /></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-input
                      v-model.number="infos.chiffreAffaireGlobalNationaux"
                      type="number"
                      v-digits-only="{ length: 16 }"
                      min="0"
                      placeholder="Veuillez saisir le chiffre d'affaire global nationaux"
                      class="float-left w-88"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Chiffre d'affaire global internationaux -->
                <b-form-group>
                  <label
                    for=""
                    class="float-left mr-4 col-form-label col-form-label-md"
                    >Chiffre d'affaire <br />global internationaux
                    <br />(FCFA)<RequiredStarComponent class=""
                  /></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-input
                      v-model.number="infos.chiffreAffaireGlobalInterNationaux"
                      type="number"
                      v-digits-only="{ length: 16 }"
                      min="0"
                      placeholder="Veuillez saisir le chiffre d'affaire global internationaux"
                      class="float-left w-88"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Chiffre d'affaire global total -->
                <b-form-group>
                  <label
                    for=""
                    class="float-left mr-5 mx-auto col-form-label col-form-label-md"
                    >Chiffre d'affaire global total (FCFA)<RequiredStarComponent
                      class="mr-2"
                  /></label>
                  <div class="my-auto">
                    {{
                      normalizeNumericValue(
                        infos.chiffreAffaireGlobalInterNationaux
                      ) +
                      normalizeNumericValue(infos.chiffreAffaireGlobalNationaux)
                    }}
                  </div>
                </b-form-group>
                <!-- Charges salariales  -->
                <b-form-group>
                  <label
                    for=""
                    class="float-left mr-3 mx-auto col-form-label col-form-label-md"
                    >Charges salariales (FCFA)<RequiredStarComponent
                      class="mr-1"
                  /></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-input
                      v-model.number="infos.chargesSalariales"
                      type="number"
                      v-digits-only="{ length: 16 }"
                      min="0"
                      placeholder="Veuillez saisir le montant de la charge salariale "
                      class="float-left w-88"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- autres charges  -->
                <b-form-group>
                  <label
                    for=""
                    class="float-left mr-5 mx-auto col-form-label col-form-label-md"
                    >Autres Dépenses (FCFA)<RequiredStarComponent class="mr-2"
                  /></label>
                  <validation-provider v-slot="{ errors }" rules="required">
                    <b-form-input
                      v-model.number="infos.autresDepense"
                      type="number"
                      v-digits-only="{ length: 16 }"
                      min="0"
                      placeholder="Veuillez saisir le montant des autres dépenses"
                      class="float-left w-88"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>
            </b-card>
          </div>

          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-2"
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <b-modal
      :ref="tipsModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Créer un formulaire"
      size="lg"
    >
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir du dernier formulaire enregistré
            </h3>
            <p class="my-1">
              Vous avez la possibilté de récupérer les données du dernier
              formulaire que vous avez enregistré pour remplir ce nouveau
              formulaire.
            </p>
            <b-button
              variant="outline-primary"
              class="float-right"
              @click="fillFormWithLastRecord"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">Créer a partir d'un formulaire vierge</h3>
            <p class="my-1">
              Vous avez la possibilté de remplir le formulaire de zéro.
            </p>

            <b-button
              variant="outline-primary"
              class="float-right"
              @click="hideModal"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
  BModal,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
import RequiredStarComponent from "@/components/RequiredStarComponent.vue";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";
import FormHeader from "../components/FormHeader.vue";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    ContentLoader,
    TipsModalCreateFromLastRecord,
    FormHeader,
    RequiredStarComponent,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  mixins: [heightTransition],
  props: {
    etablissementDetails: null,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: "formstatagence",
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      ...this.infos,
      annees: [],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      typesTransport: [
        { text: "Par route", value: "Par route" },
        { text: "Par eau", value: "Par eau" },
        { text: "Par avion", value: "Par avion" },
      ],
      agrementOptions: ["oui", "non"],
      autofillableFields: [
        {
          name: "annee",
        },
        {
          name: "departement",
        },
        {
          name: "commune",
        },
        {
          name: "arrondissement",
        },
        {
          name: "quartier",
        },
        {
          name: "nomEtablissement",
        },
        {
          name: "nomPromoteur",
        },
        {
          name: "prenomsPromoteur",
        },
        {
          name: "nationalitePromoteur",
        },
        {
          name: "adresseComplete",
          key: "adresseEtablissement",
        },
        {
          name: "telephone1",
          key: "telephoneEtablissement",
        },
        {
          name: "telephone2",
        },
        {
          name: "telephone3",
        },
        {
          name: "agrementMinistere",
        },
        {
          name: "referenceAgrement",
        },
      ],
      initialsValues: {},
      infos: {
        annee: "",
        mois: "",
        departement: "",
        commune: "",
        arrondissement: "",
        quartier: "",
        nomEtablissement: "",
        nomPromoteur: "",
        prenomsPromoteur: "",
        nationalitePromoteur: "",
        adresseComplete: "",
        telephone1: "",
        telephone2: "",
        telephone3: "",
        agrementMinistere: "",
        referenceAgrement: "",
        capacitesAccueil: {
          nombrePlacesDisponibles: null,
          nombreVisitesNationaux: null,
          nombreVisitesInterNationaux: null,
        },
        // employés
        employesSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        nouveauEmplois: {
          hommes: null,
          femmes: null,
        },
        employesNonSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        // renseignement exploitation
        durreeMoyenneVisite: null,
        chiffreAffaireGlobalNationaux: null,
        chiffreAffaireGlobalInterNationaux: null,
        chiffreAffaireGlobalTotal: null,
        chargesSalariales: null,
        autresDepense: null,
        auteur: "",
      },

      agrements: [
        { etablissement: "Les Hirondelles", reference: "hg7h878jhjKJ" },
      ],
      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,
      auteur: "",
    };
  },
  computed: {
    currentUserSousTypeEtabissement() {
      // return []
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      console.log("sousTypeEtablissement: ", sousTypeEtablissement);
      return sousTypeEtablissement;
    },
    get_sous_type() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      return sousTypeEtablissement;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.annees = utilsService.getListYears();
        this.mois = utilsService.getListMonths();
        this.pays = utilsService.getListCountries();

        if (this.formInput) {
          this.isCheckingForLastForm = false;
          this.isFillingForm = true;
          this.infos = { ...this.formInput };
          this.initialsValues = this.customCloneDeep(this.infos);
          setTimeout(() => {
            this.isFillingForm = false;
          }, 1000);
        } else {
          const etablissementDetails = this.etablissementDetails || {};
          const autofillableFields = {};
          this.autofillableFields.map((e) => {
            let foundItem = etablissementDetails[e.name];
            if (!foundItem) foundItem = etablissementDetails[e.key];
            if (foundItem) {
              autofillableFields[e.name] = foundItem;
            }
            return e;
          });

          // autofillableFields.annee = new Date().getFullYear();
          // autofillableFields.mois = this.mois[new Date().getMonth()];

          setTimeout(() => {
            this.isCheckingForLastForm = false;
          }, 1000);
          this.infos = { ...this.infos, ...autofillableFields };
          this.initialsValues = this.customCloneDeep(this.infos);
        }
      },
    },
    "infos.departement": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.commune = ""), (this.communes = []);
        this.loadCommunes();
      },
    },
    "infos.commune": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.arrondissement = ""), (this.arrondissements = []);
        this.loadArrondissements();
      },
    },
    "infos.arrondissement": {
      handler(val, old) {
        if (this.isFillingForm) return;
        (this.infos.quartier = ""), (this.quartiers = []);
        this.loadNeighborhoods();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    this.getListTypesEtablissement();
    this.loadDepartments();
    this.loadCommunes();
    this.loadArrondissements();
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaire", {
      getLastFormCreatedByUser: "getLastFormCreatedByUser",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    loadDepartments() {
      this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
        })
        .catch((err) => {});
    },

    loadCommunes() {
      this.action_loadCommunes(this.infos.departement)
        .then((res) => {
          this.communes = res;
        })
        .catch((err) => {});
    },
    loadArrondissements() {
      this.action_loadArrondissements(this.infos.commune)
        .then((res) => {
          this.arrondissements = res;
        })
        .catch((err) => {});
    },
    loadNeighborhoods() {
      this.action_loadNeighborhoods(this.infos.arrondissement)
        .then((res) => {
          this.quartiers = res;
        })
        .catch((err) => {});
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          // this.typesEts = res.data.typeetablissements || [];
        })
        .catch((err) => {});
    },
    showModal() {
      this.$refs[this.tipsModalRef].show();
    },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithLastRecord() {
      this.isFillingForm = true;
      this.infos = this.lastRecordedForm;
      setTimeout(() => {
        this.isFillingForm = false;
      }, 1000);
      this.hideModal();
    },
    sendToPreview() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      const { sousTypeEtablissement } = etablissementDetails;
      this.$refs.formRules.validate().then(async (success) => {
        const { currentUserId } = utilsService.currentUserUtils();

        if (success) {
          const canSend = true;
          // clearing data
          const form = {
            ...this.infos,
            typeEtablissement: sousTypeEtablissement,
            annee: `${this.infos.annee}`,
            ...(this.infos.departement.name && {
              departement: this.infos.departement.name,
            }),
            ...(this.infos.commune.name && {
              commune: this.infos.commune.name,
            }),
            ...(this.infos.arrondissement.name && {
              arrondissement: this.infos.arrondissement.name,
            }),
            ...(this.infos.quartier.name && {
              quartier: this.infos.quartier.name,
            }),
            ...(this.infos.nationalitePromoteur.name && {
              nationalitePromoteur: this.infos.nationalitePromoteur.name,
            }),
            auteur: currentUserId || "",
          };

          if (form.telephone2 == "") delete form.telephone2;
          if (form.telephone3 == "") delete form.telephone3;
          if (form.qrcode) delete form.qrcode;
          if (form._id) delete form._id;

          if (canSend) {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: form },
            });
          }
        } else {
          const errorFirst = this.$el.querySelector(".is-invalid");
          if (errorFirst) {
            errorFirst.scrollIntoView({ behavior: "instant" });
            errorFirst.focus();
          }
        }
      });
    },
    onFormHeaderSuccess($event) {
      console.log("$event :>> ", $event);
      this.infos = { ...this.infos, annee: $event.annee, mois: $event.mois };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";

.w-88 {
  width: 72%;
}
</style>
